
@font-face {
  font-family: inter-regular;
  src: url(../fonts/Inter/Inter-Regular.ttf);
}

@font-face {
  font-family: inter-black;
  src: url(../fonts/Inter/Inter-Black.ttf);
}

@font-face {
  font-family: inter-bold;
  src: url(../fonts/Inter/Inter-Bold.ttf);
}

@font-face {
  font-family: inter-extrabold;
  src: url(../fonts/Inter/Inter-ExtraBold.ttf);
}

@font-face {
  font-family: inter-medium;
  src: url(../fonts/Inter/Inter-Medium.ttf);
}

@font-face {
  font-family: inter-semibold;
  src: url(../fonts/Inter/Inter-SemiBold.ttf);
}

@font-face {
  font-family: lato-black;
  src: url(../fonts/Lato/Lato-Black.ttf);
}

@font-face {
  font-family: lato-bold;
  src: url(../fonts/Lato/Lato-Bold.ttf);
}

@font-face {
  font-family: lato-light;
  src: url(../fonts/Lato/Lato-Light.ttf);
}

@font-face {
  font-family: lato-regular;
  src: url(../fonts/Lato/Lato-Regular.ttf);
}

.container {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 100px auto;
  grid-template-rows: 70px auto;
  max-height: 100vh;
  background-color: #f5f5f5;
  grid-gap: 5px;
  overflow: hidden;
}


.containerR {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 12px 40px;
  background-color: #f5f5f5;
  border-radius: 10px;
}
#sidebar {
  grid-column: 1;
  grid-row: 2/-1;
  border: 0px solid #fff;
  border-radius: 4px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
  min-height: calc(100vh - 75px);
  overflow-y: auto;
  max-height: calc(100vh - 75px);
}
h1 {
  font-size: medium;
}
.item-menu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  border: 1px solid;
  border-color: #ffc057;
  background-color: var(--orangefonce);
  height: 63px;
  width: 68px;
  margin-top: 3px;
  cursor: pointer;
}

.item-menufocus {
  border-color:  var(--gold);
  background-color: var(--orangefonce);
}
.item-menu span {
  font-size: 11px;
}
.item-menufocus img,
.item-menufocus span {
  color:  var(--gold);;
  font-size: 11px;
  font-family: inter-bold;
}

.item-menunotfocus {
  border-color: #fff;
  background-color: #fff;
}

.item-menunotfocus img,
.item-menunotfocus span {
  fill: var(--gris);
  color: var(--gris);
}
.footer {
  position: absolute;
  bottom: 0;
}

.item-menu .logout {
  border-radius: 50%;
  height: 55px;
  width: 55px;
}
.logout span{
  font-size: 16px;
}

.item-menu .photo {
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

.header {
  grid-column: 1 / -1;
  grid-row: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #fff;
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  margin-right: 20px;
}

.search-bar {
  margin-right: 20px;
  flex: 0.7;
  width: 100%;
  padding: 3px;
  border: none;
  border-radius: 7px;
  background-color: #f5f5f5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.search-bar.focused {
  outline: 0.5px solid  var(--gold);;
}
.search-bar img {
  cursor: pointer;
}
.search-bar input {
  flex: 1;
  font-family: lato-bold;
  padding: 8px;
  font-size: 18px;
  font-family: lato-bold;
  background-color: #f5f5f5;
  border: none;
  outline: none;
}

.icon {
  margin-left: auto;
  display: flex;
  flex-direction: row;
}
.icons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icons img {
  margin-right: 20px;
  cursor: pointer;
  background-color: #f5f5f5;
  border-radius: 7px;
  align-items: center;
  padding: 7px;
}

.txtLoger {
  border-radius: 7px;
  background-color: var(--orangefonce);
  margin-right: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.txtLoger h3 {
  margin: 0;
  font-family: inter-bold;
  color:  var(--gold);;
}

h2 {
  font-size: 11px;
  color:  var(--gold);;
}
.main-content {
  overflow-y: auto;
  max-height: calc(100vh - 75px);
  overflow-x: hidden;
}
.main-content::-webkit-scrollbar {
  width: 6px;
  background-color: var(--orangefonce);
}
.main-content::-webkit-scrollbar-thumb {
  background-color:  var(--gold);;
  border-radius: 5px;
  opacity: 0.8;
}
.main-content::-webkit-scrollbar-thumb:hover {
  background-color:  var(--gold);;
  opacity: 1;
}
.right-sidebar {
  background-color: #fff;
  border-left: 0.5px solid rgba(159, 159, 158, 0.5);
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 30%;
  overflow: auto;
  max-height: calc(100vh - 75px);
}
table {
  width: 100%;
}
.rowT {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.cell {
  flex: 1;
  text-align: center;
}
.cell1 {
  flex: 0.7;
  text-align: center;
  padding: 7px;
  background-color: #fff;
  border-radius: 7px;
  border: 1px solid var(--gold);
  margin-right: 10px;
}

.cell1:last-child {
  margin-right: 0;
}

.command-titleth {
  font-family: inter-bold;
  font-size: 20px;
}
.command-titletd {
  background-color: #fff;
  font-family: inter-regular;
  font-size: 20px;
  border-radius: 7px;
  margin-top: 12px;
  padding-top: 16px;
  padding-bottom: 16px;
  cursor: pointer;
  color: var(--gris);
  position: relative;
}
.command-titletd.selected {
  border: 1px solid  var(--gold);;
  color: black;
}
.caption {
  margin-bottom: 12px;
  background-color: #fff;
  border-radius: 7px;
  font-family: inter-bold;
  font-size: 20px;
  padding: 8px;
  border: 1px solid var(--gold);
  text-align: center;
}

.pad {
  padding: 12px;
}
.titleC {
  font-size: 20px;
  font-family: inter-bold;
}
.titleC1 {
  margin-top: -12px;
  color: var(--gris);
  font-size: 15px;
  font-family: inter-bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
}
.vente {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #f5f5f5;
  border-radius: 7px;
  margin-bottom: 12px;
}
.venteM {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 7px;
  align-items: center;
  margin-bottom: 12px;
}
.icon-and-title {
  display: flex;
  align-items: center;
}
h5 {
  margin: 0;
  font-size: 16px;
  font-family: inter-bold;
  padding: 12px;
  text-align: center;
}
#h5 {
  background-color: white;
  margin-top: 4px;
  margin-bottom: 4px;
  margin-right: 12px;
  border-radius: 7px;
  padding-top: 7px;
  padding-bottom: 0px;
}
h6 {
  margin: 0;
  padding-top: 12px;
}
.footerR {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: var(--orangefonce);
  border-radius: 7px;
  margin-bottom: 12px;
  margin-left: 12px;
  margin-right: 12px;
}
#h3 {
  background-color: #f5f5f5;
}
.row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: stretch;
}
.rowModal {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}
.rowS {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.rowL {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
footer {
  margin-top: auto;
}
.bottomR {
  padding: 12px;
  background-color: #f5f5f5;
}
.bottomR1 {
  padding: 12px;
}
h4 {
  font-size: 13px;
  font-family: inter-regular;
  margin: 0;
  margin-bottom: 7px;
}
.loginItem {
  background-color: rgb(241, 241, 241,0.75);
  border-radius: 10px;
  width: 100%;
  padding-left: 16px;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}

.loginItem > div {
  flex: 1;
  width: 100%;
}

.columnL {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.columnL1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.label {
  font-family: lato-bold;
  font-size: 20px;
}
.columnL span {
  font-family: lato-bold;
}

.rowEnd {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
}

.rowEnd span {
  color: var(--withGold);
  text-align: end;
  font-family: lato-bold;
  font-size: 16px;
  cursor: pointer;
}
.columnContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(252, 252, 252, 0.7);
  border-radius: 10px;
  padding: 40px;
}
.txtL {
  font-family: lato-bold;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 10px;
}
.itemM {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--gold);
  padding: 3px;
  overflow-y: auto;
  overflow-x: auto;
  max-width: 900px;
  position: relative;
}
.rowItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; 
  align-items: center;
  flex-wrap: nowrap; 
  overflow-x: auto; 
  height: 45px;
}
.subMenu {
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 10px;
  position: absolute; 
  top: 100%; 
  left: 0;
  z-index: 3; 
}
.menuItem {
  position: relative;
}
.rowItem::-webkit-scrollbar {
  width: 6px; 
  height: 8px; 
}
.rowItem::-webkit-scrollbar-thumb {
  background-color: var(--withGold); 
  border-radius: 7px; 
}

.rowItem::-webkit-scrollbar-thumb:hover {
  background-color: var(--gold); 
}

.rowItem::-webkit-scrollbar-track {
  background-color: #fff; 
  border-radius: 7px; 
  margin-left: 3px;
  margin-right: 3px;
}
.subMenu span {
  display: block;
  padding: 3px;
  font-family: inter-regular;
  cursor: pointer;
}

.subMenu span:hover {
  background-color: #eee;
  color: var(--gold);
  
}
.itemM span:not(:last-child){
  margin-right: 10px;
}
.itemM span {
  font-size: 16px;
  font-family: inter-semibold;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: inline-block;
  white-space: nowrap;
  padding: 3px;
}
.itemM span.selected {
  border: 1px solid  var(--gold);
  background-color: var(--orangefonce);
  color: var(--gold);
}
.itemM span:hover{
  background-color: var(--orangefonce);
}
.rowM {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.addBraiding {
  flex-basis: calc(20% - 15px);
  position: relative;
  border-radius: 7px;
  border: 1px solid white;
  margin: 15px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: #fff;
}
.addBraid {
  position: absolute;
  top:85px;
  left: 95px; 
  z-index: 2; /* Image superposée */
}
.addBraid span{
  font-size: 40px;
  color: var(--orangefonce);
  font-weight: 900;
}
.addBraiding img {
  max-width: 109px;
  max-height: 109px;
  border-radius: 10px;
  width: 109px;
  height: 109px;
}
.addBraiding h6{
  font-size: 17px;
  font-family: inter-bold;
  color: var(--withGold);
}
.dish {
  flex-basis: calc(20% - 15px);
  position: relative;
  border-radius: 7px;
  border: 1px solid var(--withGold);
  margin: 15px;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.dish img {
  max-width: 109px;
  max-height: 109px;
  border-radius: 10px;
  width: 109px;
  height: 109px;
}
.dish.selected {
  border: 1px solid  var(--gold);
}

.promotion-label {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ea0000;
  color: white;
  font-family: inter-regular;
  padding: 3px 8px;
  transform: rotate(15deg);
}
.padding {
  padding: 15px;
}

/* Style  contenu du table */
.main-contentT {
  grid-column: 2 / -1;
  grid-row: 2;
  overflow-y: auto;
  max-height: calc(100vh - 75px);
  overflow-x: hidden;
}
.itemT {
  background-color: #fff;
  border-radius: 10px;
  border: 1px solid var(--gold);
  padding: 8px;
}
.itemT span {
  font-family: inter-bold;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 3px 16px;
}
.itemT span.selected {
  border: 1px solid  var(--gold);;
  background-color: var(--orangefonce);
  color:  var(--gold);;
}

.table-header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.icon-addtable {
  cursor: pointer;
}

/* style history */
.main-contentH {
  display: flex;
  flex-direction: column;
  grid-column: 2;
  width: 100%;
  height: 100%;
  flex: 1;
}
.main-contentHR {
  display: flex;
  flex-direction: column;
  grid-column: 2;
  max-height: calc(100vh - 75px);
  overflow-y: auto;
  padding-right: 17px;
  
}
.main-contentH1{
  grid-column: 2;
  width: 100%;
  max-height: calc(100vh - 75px);
  overflow-y: auto;
}
.main-contentH1R{
  width: 100%;
}

.right-sidebarH {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: calc(100vh - 75px);
  overflow-y: auto;
}
.rsv-contain {
  flex: 1;
  width: 100%;
  border: 1px solid var(--gold);
  background-color: #fff;
  border-radius: 7px;
}

.order-card {
  background-color: #fff;
  border: 1px solid var(--gold);
  border-radius: 7px;
  padding: 8px;
  margin-bottom: 10px;
  box-shadow: 4px 4px 8px 0px rgba(0, 0, 0, 0.07);
}

.order-card .header {
  font-size: 18px;
  font-family: inter-regular;
}

.order-card .details {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-card .details span {
  font-size: 14px;
  color: var(--gris);
}

.order-card .details .actions {
  display: flex;
  align-items: center;
}

.order-card .details .actions img {
  border-radius: 7px;
  margin-left: 10px;
  cursor: pointer;
}

.order-card .details .actions img:hover {
  background-color: #eee;
}

.order-card .details .actions img:focus {
  outline: none;
}

.order-card .header.cancelled {
  color: var(--orange);
}

.order-card .header.pending {
  color: #ffc107;
}

.order-card .header.completed {
  color: #28a745;
}

/* hom admin */

.card {
  background-color: white;
  padding: 12px;
  border-radius: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: 16px;
  width: 100%;
}

.card-title {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-value {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 5px;
}

.card-icon {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  margin-bottom: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card-title-green {
  color: #09aa29;
}

.card-title-red {
  color: var(--orange);
}

.card-title-orange {
  color:  var(--gold);;
}

.card-icon-green {
  background-color: #09aa29;
}
.articles-card-img{
  transform: rotate(15deg);
}
.card-icon-red {
  background-color: var(--orange);
}

.card-icon-orange {
  background-color:  var(--gold);;
}

.articles-section {
  display: flex;
  flex-direction: column;
  background-color: #fff;
  border-radius: 10px;
  padding: 12px;
  max-height: 400px; /* Définir la hauteur maximale de la section */
  overflow-y: auto;
}

.articles-card {
  background-color: #fff;
  padding: 4px;
  border-radius: 5px;
  text-align: left;
  border: 1px solid rgba(252, 128, 25, 0.2);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}
.articles-card:not(:last-child) {
  margin-right: 10px;
}
.row-card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.row-card1 {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.articles-card-title {
  font-size: 20px;
  margin-bottom: 5px;
}

.articles-card-info {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.articles-card-info .title span {
  font-size: 10px;
  margin-bottom: 5px;
  font-family: inter-semibold;
}
.articles-card-info .titleColored span {
  font-size: 10px;
  font-family: inter-semibold;
  margin-bottom: 5px;
  color: var(--gold);
}
.articles-card-info .titleColored1 span {
  font-size: 10px;
  font-family: inter-semibold;
  margin-bottom: 5px;
  color:  var(--withGold);
}

.employees-section {
  background-color: #fff;
  margin-top: 15px;
  padding: 8px;
}

.employees-list {
  list-style: none;
  padding: 0;
}
.employees-title-section {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 5px;
}
.divider {
  width: 100%;
  height: 1px;
  background-color: rgb(0, 0, 0, 0.07);
  margin-top: 5px;
  margin-bottom: 5px;
}
.employees-title-section-name span {
  font-size: 20px;
}
.employees-title-section-btn span {
  font-size: 20px;
  color: var(--gris);
  cursor: pointer;
}
.employees-list-item {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.employees-list-item-img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}
.employees-list-item-img1 {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.employees-list-item-info {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
}

.employees-list-item-name {
  font-size: 18px;
  font-weight: bold;
}

.employees-list-item-role {
  font-size: 15px;
  color: #9f9f9f;
}

.employees-list-item-hours {
  font-size: 15px;
  color: #9f9f9f;
}

.gestion-employee-section {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-section {
  position: relative;
  z-index: 2;
}

.bg-section {
  z-index: 1;
  position: absolute;
  bottom: -25px;
  width: 100%;
  background-color: #fff;
  height: 200px;
  border-radius: 10px;
}
.bg-content {
  z-index: 3;
  position: absolute;
  bottom: 0;
  width: 60%;
  border: 1px solid  var(--gold);;
  background-color: var(--orangefonce);
  border-radius: 10px;
  padding: 8px;
  cursor: pointer;
}
.arrow-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.subheading span {
  font-size: 20px;
  color:  var(--gold);;
}
.subheading1 span {
  font-size: 15px;
  color:  var(--gold);;
}
.container-back {
  padding: 8px;
  border-radius: 50%;
  background-color:  var(--gold);;
  color: #fff;
  font-size: 24px;
}
.cmd-content {
  flex: 1;
  background-color: #fff;
  border-radius: 7px;
  padding: 8px;
  margin-top: 43px;
}

/* right-sidebar-menuAdmin-content */
.right-sidebar-menuAdmin-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex: 1;
  height: 100%;
}
.right-sidebar-menuAdmin-content span {
  color:  var(--gold);;
  font-size: 17px;
  font-family: inter-bold;
  text-align: center;
  padding: 16px;
}
.right-sidebar-menuAdmin-content img {
  cursor: pointer;
}

/* modal add table */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 5px;
  max-width: 600px;
  max-height: 600px;
  overflow: auto;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}



.modal .form-group {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.modal label {
  margin-bottom: 8px;
  font-weight: bold;
}
.modal select {
  font-family: lato-bold;
  padding: 0 4px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url("../icons/picker.svg");
  background-repeat: no-repeat;
  background-position: right 10px center;
  border: 1px solid rgb(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  height: 35px;
  color: var(--gris);
}
.modal input[type="text"],
input[type="number"],input[type="email"],input[type="password"] {
  font-family: lato-bold;
  padding: 0 4px;
  border: 1px solid rgb(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 8px;
  font-size: 18px;
  height: 35px;
  color: var(--gris);
}
.modal textarea{
  font-family: lato-bold;
  border: 1px solid rgb(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 8px;
  font-size: 18px;
  color: var(--gris);
}
.modal input[type="time"] {
  background-color: #fff;
  font-family: lato-bold;
  font-size: 18px;
   padding:0;
   border:none;
   color: var(--gris) 
}
.time-input-container {
  display: flex;
  align-items: center;
  padding: 0 4px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  height: 35px;
  color: var(--gris);
}
.right-sidebar input[type="text"],
input[type="number"],select {
  padding: 0 4px;
  border: 1px solid rgb(0, 0, 0, 0.25);
  background-color: #fff;
  border-radius: 8px;
  font-size: 16px;
  height: 35px;
  color: var(--gris);
}

.modal .button {
  padding: 10px 20px;
  background-color: var(--gold);
  font-size: 16px;
  font-family: inter-bold;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  
}
.button-container {
  display: flex;
  justify-content: center;
  width: 100%;
}
.marginB{
  margin-bottom: 20px;
}

.close-button {
  background-color: var(--orange);
  cursor: pointer;
  border-radius: 50%;
  border: none;
  color: #fff;
}

.day-button {
  border-radius: 50%;
  padding: 6px;
  background-color: #d9d9d9;
  color: var(--gris);
  font-size: 11px;
  font-family: inter-bold;
  border: none;
  cursor: pointer;
}
.day-button.selected {
  background-color: #09aa29;
  color: white;
}

.day-button:not(:last-child) {
  margin-right: 6px;
}

.column{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
}




.category-list {
  width: 100%;
  margin-top: 2em;
}

.category-item {
  border: 1px solid #ccc;
  margin-bottom: 1em;
  padding: 1em;
  border-radius: 5px;
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.category-header span{
  font-family: inter-semibold;
}
.crud-icons {
  display: flex;
  align-items: center;
}

.crud-icons > * {
  margin-left: 10px;
  cursor: pointer;
}
.icon-delete{
  color: #e57373;
}
.icon-edit{
  color: #64b5f6;
}
.icon-eye{
  color:#09aa29;
}
.icon-eyedrop{
  color:#ffc107;
}
.icon-add{
  color: var(--gold);
}
.crud-icons > *:hover {
  opacity: 0.8;
}
.category-types {
  margin-top: 1em;
}

.type-item {
  padding: 8px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.type-item1 {
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.type-itemG {
  padding: 8px;
  border-top: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.type-actions {
  display: flex;
  align-items: center;
}
.icon-small {
  margin-left: 5px;
  cursor: pointer;
}

.icon-small:hover {
  opacity: 0.8;
}
input[type="checkbox"]{
  transform: scale(1.5);
  accent-color: var(--gold);  
  margin: 8px;
}
.image-preview {
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* CSS pour le spinner */
.loader {
  border: 8px solid #f3f3f3; /* Couleur de l'arrière-plan du spinner */
  border-top: 8px solid #3498db; /* Couleur de la bordure du spinner */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered-text {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 50%;
  left: 50%;
}
.checkbox-group input[type="radio"] {
  transform: scale(1.5);
  margin-right: 5px;
}
.checkbox-group label {
  align-items: center;
  font-size: 14px;
  margin-right: 8px;
}
/* Animation du spinner */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.order-icon{
position: absolute;
top: 1px;
right: 1px;
}

.employer-details {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  justify-content: center;
  align-items: start;
}

.detail-item {
  display: flex;
  align-items: center;
}
.value {
  font-size: 0.95em;
  color: #333;
}

.order-form {
  display: flex;
  flex-direction: column;
  max-width: 400px;
  margin: 20px;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

label {
  margin-bottom: 10px;
  font-weight: bold;
}

select,
input {
  margin-bottom: 20px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  font-size: 16px;
}

button {
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #0056b3;
}

.message {
  color: red;
  margin-top: 10px;
  text-align: center;
}

.order-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 800px; /* Maximum width for the form */
  margin: 0 auto; /* Center the form */
}

.form-scroll {
  max-height: 500px; /* Fixed height for the scrollable area */
  overflow-y: auto; /* Enable vertical scrolling */
  width: 100%; /* Make it responsive */
  padding: 20px; /* Padding around the form */
  border: 1px solid #ccc; /* Optional border */
  border-radius: 5px; /* Rounded corners */
  background-color: #f9f9f9; /* Light background */
}

.categories {
  margin-top: 20px; /* Space between form and categories */
}

.message {
  color: red; /* Error messages in red */
  margin-top: 10px; /* Space above the message */
}

.layout-container {
  margin-bottom: 0;

}

.scrollable-container {
  max-height: 400px; /* Adjust height as needed */
  overflow-y: auto; /* Enables vertical scrolling */
  border: 0px solid #ccc; /* Optional: Adds a border for better visibility */
  padding: 10px; /* Optional: Adds some padding */
  margin-top: 20px; /* Optional: Adds margin above */
}

.main-contentUser { 
  display: flex;
  flex-direction: column;
  align-items: center; /* Center content horizontally */
  overflow-y: auto;
  max-height: calc(100vh - 75px);
  width: 100%; /* Ensure full width */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  #sidebar {
    width: 60px; /* Reduce the width of the sidebar */
  }

  .item-menu {
    justify-content: center; /* Center items */
  }

  .item-menu span {
    display: none; /* Hide labels */
  }

  .item-menu img {
    width: 24px; /* Reduce icon size */
    height: 24px; /* Maintain aspect ratio */
  }
}

/* Media query for smaller screens */
@media (max-width: 768px) { /* Adjust the max-width as needed */
  .container {
    grid-template-columns: auto auto; /* Change for small screens */
  }
}

.expandable-menu {
  display: flex;
  flex-direction: column; /* Stack items vertically */
  position: absolute; /* Position relative to header */
  right: 0; /* Align to the right */
  top: 50px; /* Adjust according to header height */
  background: white; /* Background color */
  border: 1px solid #ccc; /* Optional border for visibility */
  z-index: 10; /* Ensure it sits above other content */
  padding: 10px; /* Optional padding */
  align-items: center; /* Center items horizontally */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Optional shadow for depth */
}

.menu-container {
  position: relative; /* Needed for positioning dropdown */
}

.menu-items .icon {
  margin: 5px 0; /* Add vertical margin between icons */
  cursor: pointer; /* Change cursor on hover */
}

.menu-icon {
  cursor: pointer;
  margin-left: 10px; /* Margin on the left */
}

/* Media query for smaller screens */
@media (max-width: 600px) {
  .icon {
    margin-left: 5px; /* Adjust spacing for small screens */
    width: 20px; /* Fixed size for icons */
    height: 20px; /* Fixed size for icons */
  }

  .search-bar input {
    font-size: 11px; /* Smaller font size for input */
  }

  .header {
    display: flex;
    justify-content: space-between; /* Space between logo, search, and menu icon */
    align-items: center; /* Center items vertically */
    padding: 10px; /* Optional padding */
    position: relative; /* For absolute positioning of the expandable menu */
  }

  .search-bar {
    display: flex; /* Align input and search icon */
    align-items: center; /* Center items vertically */
    margin: 0 10px; /* Optional margin for spacing */
  }

  .menu-items {
    display: flex; /* Use flexbox for horizontal layout */
    flex-direction: column; /* Stack items vertically */
    align-items: center; /* Center icons horizontally */
  }

}

