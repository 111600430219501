.table-card {
    background-color: #fff;
    border: 1px solid var(--withGold);
    border-radius: 7px;
    padding: 8px;
    text-align: center;
    width: 220px;
    margin-top: 10px;
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position:relative;
    cursor: pointer;
  }
  .table-card.selected{
    border: 1px solid var(--gold);
  }
  .display-button{
    position: absolute;
    bottom: 3px;
    right: 5px;
    z-index: 2;
  }
  
  .table-card h3 {
    margin-bottom: 5px;
    color: black;
    font-family: inter-medium;
    font-size: 20px;
  }
  .table-card .row {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .table-card .column {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }
  .table-card .row .chairH {
    background-color: #d9d9d9;
    border-radius: 5px;
  }
  .table-card .table {
    height: 130px;
    width: 150px;
    max-height: 130px;
    max-width: 150px;
    border-radius: 5px;
    margin: 10px;
  }
  .table-card .chair-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .table-card .chairW {
    background-color: #d9d9d9;
    border-radius: 5px;
  }
  .table-card .status {
    font-weight: bold;
    margin-top: 10px;
  }
  
  .table-card .status.available {
    color: #09aa29;
  }
  
  .table-card .status.reserved {
    color: var(--gold);
  }
  
  .table-card .status.taken {
    color: #f60202;
  }