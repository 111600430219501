/* les tables  */
.table-card1 {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 7px;
    padding: 16px;
    width: 220px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
 
  .table-card1 .row {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .table-card1 .column {
    display: flex;
  justify-content: center;
  flex-direction: column;
  }
  .table-card1 .chairH {
    background-color: #d9d9d9;
    border-radius: 5px;
  }
  .table-card1 .table {
    min-height: 130px;
    min-width: 150px;
    border-radius: 5px;
    margin: 10px;
  }
  .table-card1 .chair-container {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .table-card1 .chairW {
    background-color: #d9d9d9;
    border-radius: 5px;
  }
 