.rowbtn span {
  border: none;
  font-size: 17px;
  font-family: inter-bold;
}
.rowbtn {
  padding: 8px;
  border-radius: 8px;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.rowbtn1 {
  padding: 8px;
  cursor: pointer;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.rowbtn1 span {
  border: none;
  font-size: 20px;
  font-family: lato-bold;
}

@media screen and (max-width: 768px) {
  .rowbtn span,
  .rowbtn1 span {
    font-size: 1em;
  }
}

@media screen and (max-width: 768px) {
  .rowbtn ,
  .rowbtn1  {
    padding: 1em;
  }
}