.input-container {
    position: relative;
    width: 100%;
}

.input-container input {
    font-family: lato-bold;
    padding: 8px;
    font-size: 18px;
    background-color: rgba(241, 241, 241, 0.3);
    outline: none;
    box-sizing: border-box;
    border: none;
}

.input-container input:focus {
    outline: 1.5px solid var(--gold);
}

.toggle-password {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    cursor: pointer;
    font-size: 16px;
}

@media screen and (max-width: 768px) {
    input {
        padding: 1em;
        font-size: 1em;
    }

    .toggle-password {
        font-size: 14px;
    }
}
