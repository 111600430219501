/* src/components/skeletonLoader.css */
.skeleton {
  margin: 0;
  padding: 0;
  display: grid;
  grid-template-columns: 100px 2fr 1fr;
  grid-template-rows: 70px auto;
  min-height: 100vh;
  background-color: #f5f5f5;
  grid-gap: 5px;
  overflow: hidden;
}
.skeleton-sidebar{
  grid-column: 1;
  grid-row: 2 / -1;
  border: 0px solid #e0e0e0;
  border-radius: 4px;
  background-color: #e0e0e0;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 7px;
}
.skeleton-header {
  grid-column: 1 / 4;
  grid-row: 1;
  display: flex;
  align-items: center;
  flex-direction: row;
  background-color: #e0e0e0;
}

.skeleton-content {
  grid-column: 2;
  grid-row: 2;
  overflow-y: auto; 
  max-height: calc(100vh - 75px);
  overflow-x: hidden;
  background: linear-gradient(90deg, rgba(224, 224, 224, 0) 0%, rgba(224, 224, 224, 0.5) 50%, rgba(224, 224, 224, 0) 100%);
  animation: loadingShimmer 1.5s infinite;
}

@keyframes loadingShimmer {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}
.skeleton-righbar{
  grid-column: 3;
  grid-row: 2;
  background-color: #e0e0e0;
  border-left: 0.5px solid rgba(159, 159, 158 ,0.5);
  display: flex;
  flex-direction: column;
}

