.confirm-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.confirm-dialog-contain {
    position: absolute; /* Changez fixed en absolute pour le positionner par rapport à son parent */
    top: 50%; /* Centrez verticalement */
    left: 50%;
    transform: translate(-50%, -50%); 
    width: 100%;
    max-width: 500px;
    background: white;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.5);
    padding: 20px;
    border-radius: 10px;
    z-index: 1001;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
 
  .button {
    padding: 10px 20px;
    font-size: 16px;
    font-family: inter-bold;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    
  }
  .button.confirm {
    background-color: red;
    color: white;
  }
  
  .button.cancel {
    background-color: var(--gold);
    color: white;
  }
  .confirm-dialog .button {
    margin: 5px;
  }